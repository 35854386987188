import { facadeApi } from 'api'
import { ImportEntityResponse } from 'components/importEntitiesModal/utils'
import { RouteModules } from 'types/masterData/utils'

export interface UploadFileParams {
  file: File
  module: RouteModules
}

export const importEntityApi = ({ file, module }: UploadFileParams) => {
  const formData = new FormData()
  formData.append('file', file)

  return facadeApi.post<ImportEntityResponse>(`/${module}/bulk/csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
