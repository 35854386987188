import { facadeApi } from 'api'
import { Industry, IndustryFormDTO } from 'types/masterData/industries'

export interface Params {
  industry: IndustryFormDTO
  mainId: string
  secondaryId: string
}

export const mergeIndustryApi = ({ industry, mainId, secondaryId }: Params) =>
  facadeApi.post<Industry>(`/industries/${mainId}/merge-with/${secondaryId}`, industry)
