import { facadeApi } from 'api'
import { Market, MarketFormDTO } from 'types/masterData/markets'

export interface Params {
  market: MarketFormDTO
  mainId: string
  secondaryId: string
}

export const mergeMarketsApi = ({ market, mainId, secondaryId }: Params) =>
  facadeApi.post<Market>(`/v2/markets/${mainId}/merge-with/${secondaryId}`, market)
