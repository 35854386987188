import { facadeApi } from 'api'
import { Agency, AgencyFormDTO } from 'types/masterData/agencies'

export interface Params {
  agency: AgencyFormDTO
  mainId: string
  secondaryId: string
}

export const mergeAgencyApi = ({ agency, mainId, secondaryId }: Params) =>
  facadeApi.post<Agency>(`/agencies/${mainId}/merge-with/${secondaryId}`, agency)
