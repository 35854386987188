import { facadeApi } from 'api'
import { getApiModulePath } from 'api/common/utils'
import { EntityAllFields, RouteModules } from 'types/masterData/utils'

export interface Params {
  module: RouteModules
  entryId: string
}

export const fetchEntityApi = ({ entryId, module }: Params) =>
  facadeApi.get<EntityAllFields>(`/${getApiModulePath(module)}/${entryId}`)
