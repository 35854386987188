import qs from 'qs'

import { facadeApi } from 'api'
import { PaginationParams, SortOrder } from 'api/common/types'
import { CurrenciesListSortBy } from 'api/masterData/fetchers/fetchCurrenciesList'

export type Params = PaginationParams<{
  search?: string
  sortBy?: CurrenciesListSortBy
  orderBy?: SortOrder
}>

export const fetchCurrenciesExportApi = ({ search, page, itemsPerPage, sortBy, orderBy }: Params) =>
  facadeApi.get<Blob>('/currencies/export', {
    params: {
      page,
      itemsPerPage,
      sortBy,
      orderBy,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
    responseType: 'blob',
  })
