import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { getApiModulePath } from 'api/common/utils'
import { CHANGE_HISTORY_SIZE } from 'pages/components/entityChangeHistory/EntityChangeHistory'
import { ChangeHistory } from 'types/masterData/changeHistory'
import { RouteModules } from 'types/masterData/utils'

export type Params = PaginationParams<{
  module: RouteModules
  entryId: string
}>

export type ChangeHistoryListResponse = PaginatedResponse<ChangeHistory>
export const fetchEntityChangeHistoryApi = ({ module, entryId, page, itemsPerPage = CHANGE_HISTORY_SIZE }: Params) =>
  facadeApi.get<ChangeHistoryListResponse>(`/${getApiModulePath(module)}/${entryId}/versions`, {
    params: {
      page,
      itemsPerPage,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
