import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Market, MarketType } from 'types/masterData/markets'

export enum MarketsListSortBy {
  name = 'name',
  aliases = 'aliases',
  isoAlpha2 = 'isoAlpha2',
  isoAlpha3 = 'isoAlpha3',
  geographies = 'geographies',
  updatedAt = 'updatedAt',
}

export type Params = PaginationParams<{
  search?: string
  filterType?: MarketType
  parentId?: string[]
  regionId?: string[]
  sort?: string
}>

export type MarketsListResponse = PaginatedResponse<Market>

export const fetchMarketsList = ({ search, filterType, parentId, regionId, page, sort, itemsPerPage }: Params) =>
  facadeApi.get<MarketsListResponse>('/v2/markets', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        ...(!!search && { search }),
        ...(!!parentId && { parentId }),
        ...(!!filterType && { type: filterType }),
        ...(!!regionId && { regionId }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
