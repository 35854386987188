import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Geography } from 'types/masterData/geographies'

export enum GeographiesListSortBy {
  name = 'name',
  aliases = 'aliases',
  updatedAt = 'updatedAt',
}

export type Params = PaginationParams<{
  search?: string
  sort?: string
}>

export type GeographiesListResponse = PaginatedResponse<Geography>

export const fetchGeographiesList = ({ search, page, itemsPerPage, sort }: Params) =>
  facadeApi.get<GeographiesListResponse>('/geographies', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
