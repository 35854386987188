import { facadeApi } from 'api'
import { Currency, CurrencyFormDTO } from 'types/masterData/currencies'

export interface Params {
  currency: CurrencyFormDTO
  mainId: string
  secondaryId: string
}

export const mergeCurrencyApi = ({ currency, mainId, secondaryId }: Params) =>
  facadeApi.post<Currency>(`/currencies/${mainId}/merge-with/${secondaryId}`, currency)
