import { facadeApi } from 'api'
import { Brand, BrandFormDTO } from 'types/masterData/brands'

export interface Params {
  brand: BrandFormDTO
  mainId: string
  secondaryId: string
}

export const mergeBrandApi = ({ brand, mainId, secondaryId }: Params) =>
  facadeApi.post<Brand>(`/brands/${mainId}/merge-with/${secondaryId}`, brand)
